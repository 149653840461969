import { activationConfigPromise, remoteConfig } from 'services/Firebase/initFirebase';

import { getAll } from 'firebase/remote-config';

export const changePathNameToScreenId = (pathName = window.location.pathname) => {
    const screenId = pathName.replace(/^\/|\/$/g, '').replace(/(\/([a-z]{2})\/)|\//g, '_');

    return screenId || 'log_in';
};

export const getAbTestName = () =>
    activationConfigPromise.then(() => {
        const allExperiments = getAll(remoteConfig);

        const configObject = Object.keys(allExperiments).reduce(
            (acc, currValue: string) => {
                acc[currValue] = allExperiments[currValue].asString();

                return acc;
            },
            {} as {
                [key: string]: string;
            }
        );

        const abTestName = Object.entries(configObject)
            .map((entry) => entry.join('__'))
            .join(', ');

        return abTestName;
    });
