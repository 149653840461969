import config from 'config';

import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';

import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: config.FIREBASE_API_KEY,
    authDomain: config.FIREBASE_AUTH_DOMAIN,
    projectId: config.FIREBASE_PROJECT_ID,
    storageBucket: config.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID,
    appId: config.FIREBASE_APP_ID,
    measurementId: config.FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(app);

const activationConfigPromise = fetchAndActivate(remoteConfig);

export { remoteConfig, activationConfigPromise };
