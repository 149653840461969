import * as action from './actions';

import * as actionTypes from './actionTypes';

import { InferValueTypes } from 'types/commonTypes';
import { UserState } from './types';

export const initialState = {
    country: null,
    date_of_birth: null,
    email: null,
    fitness_level: null,
    gender: null,
    goal: null,
    height: null,
    is_password: null,
    language: null,
    last_name: null,
    localization: null,
    name: null,
    product_list: null,
    target_bodytype: null,
    target_weight: null,
    target_zone: null,
    units: null,
    user_id: null,
    weight: null,
    token: null,
    user_error: null,
    is_personal_plan: false,
    problem_zones: [],
    is_mobile_user: null,
    features: [],
};

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const userReducer = (state: UserState = initialState, action: ActionType): UserState => {
    switch (action.type) {
        case actionTypes.RESET_USER_DATA:
            return { ...initialState };

        case actionTypes.UPDATE_USER_SUCCESS:

        case actionTypes.GET_CURRENT_USER_SUCCESS:
            return { ...state, ...action.payload };

        case actionTypes.UPDATE_USER_ERROR:
            return { ...state, user_error: action.payload };

        case actionTypes.SET_USER_FEATURES:
            return { ...state, features: action.payload };

        default:
            return state;
    }
};

export default userReducer;
